import React, { Component } from 'react'
import SingleHomePage from './pages/SingleHomePage'


export default class App extends Component {
  render() {
    return (
      <SingleHomePage/>
    )
  }
}
